@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: quicksand;
  background-color: #fafafa;
}

/* Styles for the scrollbar track */
::-webkit-scrollbar {
  width: 5px;
  height: 5px; /* Width of the scrollbar */
}

/* Styles for the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #2cbcef; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Border radius of the scrollbar thumb */
}

/* Styles for the scrollbar track (the area where the thumb moves) */
::-webkit-scrollbar-track {
  background-color: #f3f4f6; /* Color of the scrollbar track */
}

/* Styles for the scrollbar corner */
::-webkit-scrollbar-corner {
  background-color: transparent; /* Color of the scrollbar corner */
}

/* Removes the default arrows in an input field */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
